
<template>
  <div>
    <div
      class="aside aside-left aside-fixed d-flex flex-column flex-row-auto"
      :class="burgerMenu ? 'aside-on' : ''"
      id="tc_aside"
    >
      <!--begin::Brand-->
      <div class="brand flex-column-auto" id="tc_brand">
        <!--begin::Logo-->

        <router-link to="/admin/dashboard" class="brand-logo">
          <img
            class="brand-image"
            style="height: 25px"
            alt="Logo"
            src="/assets/images/misc/r.png"
          />
          <span class="brand-text"
            ><img
              style="height: 25px"
              alt="Logo"
              src="/assets/images/misc/logo.png"
          /></span>
        </router-link>
        <!--end::Logo-->
      </div>

      <!--begin::Aside Menu-->
      <div
        class="aside-menu-wrapper flex-column-fluid overflow-auto h-100"
        id="tc_aside_menu_wrapper"
      >
        <!--begin::Menu Container-->
        <div
          id="tc_aside_menu"
          class="aside-menu mb-5"
          data-menu-vertical="1"
          data-menu-scroll="1"
          data-menu-dropdown-timeout="500"
        >
          <!--begin::Menu Nav-->
          <div id="accordion">
            <ul class="nav flex-column">
              <!--begin::Nav Dashboard -->

              <li class="nav-item">
                <router-link to="/admin/dashboard" class="nav-link">
                  <span class="svg-icon nav-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-home"
                    >
                      <path
                        d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"
                      ></path>
                      <polyline points="9 22 9 12 15 12 15 22"></polyline>
                    </svg>
                  </span>
                  <span class="nav-text"> Dashboard </span>
                </router-link>
              </li>

              <!--begin::Nav Media -->

              <li class="nav-item" v-if="$parent.permissions.includes('media')">
                <a
                  class="nav-link"
                  data-toggle="collapse"
                  href="javascript:void(0)"
                  data-target="#media"
                  role="button"
                  aria-expanded="false"
                  aria-controls="media"
                  @click.prevent="setActive('media')"
                  :class="{ active: isActive('media') }"
                >
                  <span class="svg-icon nav-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-image"
                    >
                      <rect
                        x="3"
                        y="3"
                        width="18"
                        height="18"
                        rx="2"
                        ry="2"
                      ></rect>
                      <circle cx="8.5" cy="8.5" r="1.5"></circle>
                      <polyline points="21 15 16 10 5 21"></polyline>
                    </svg>
                  </span>
                  <span class="nav-text">Medios</span>
                  <i class="fas fa-chevron-right fa-rotate-90"></i>
                </a>
                <div
                  :class="{ 'collapse nav-collapse': !isActive('media') }"
                  class="nav-collapse"
                  id="media"
                  data-parent="#accordion"
                >
                  <ul class="nav flex-column">
                    <li
                      class="nav-item"
                      v-if="$parent.permissions.includes('media-settings')"
                    >
                      <router-link
                        to="/admin/media-setting"
                        class="nav-link sub-nav-link"
                      >
                        <span
                          class="
                            svg-icon
                            nav-icon
                            d-flex
                            justify-content-center
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10px"
                            height="10px"
                            fill="currentColor"
                            class="bi bi-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                            />
                          </svg>
                        </span>

                        <span class="nav-text">Opciones multimedia</span>
                      </router-link>
                    </li>

                    <li
                      class="nav-item"
                      v-if="$parent.permissions.includes('manage-media')"
                    >
                      <router-link
                        to="/admin/media"
                        class="nav-link sub-nav-link"
                      >
                        <span
                          class="
                            svg-icon
                            nav-icon
                            d-flex
                            justify-content-center
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10px"
                            height="10px"
                            fill="currentColor"
                            class="bi bi-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                            />
                          </svg>
                        </span>
                        <span class="nav-text">Administrar multimedia</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>

              <!--begin::Nav Catalog -->

              <li
                class="nav-item"
                v-if="$parent.permissions.includes('catalog')"
              >
                <a
                  class="nav-link"
                  data-toggle="collapse"
                  href="javascript:void(0)"
                  @click.prevent="setActive('catalog')"
                  :class="{ active: isActive('catalog') }"
                >
                  <span class="svg-icon nav-icon">
                    <i class="fas fa-boxes font-size-h4"></i>
                  </span>
                  <span class="nav-text">Catalogo</span>
                  <i class="fas fa-chevron-right fa-rotate-90"></i>
                </a>
                <div
                  :class="{ 'collapse nav-collapse': !isActive('catalog') }"
                  class="nav-collapse"
                  id="catalog"
                  data-parent="#accordion"
                >
                  <div id="accordion1">
                    <ul class="nav flex-column">
                      <li
                        class="nav-item"
                        v-if="$parent.permissions.includes('product-unit-list')"
                      >
                        <router-link
                          to="/admin/product-unit"
                          class="nav-link sub-nav-link"
                        >
                          <span
                            class="
                              svg-icon
                              nav-icon
                              d-flex
                              justify-content-center
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="10px"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                            </svg>
                          </span>
                          <span class="nav-text">Unidades de medida</span>
                        </router-link>
                      </li>
                      <li
                        class="nav-item"
                        v-if="
                          $parent.permissions.includes('product-attribute-list')
                        "
                      >
                        <router-link
                          to="/admin/product-attribute"
                          class="nav-link sub-nav-link"
                        >
                          <span
                            class="
                              svg-icon
                              nav-icon
                              d-flex
                              justify-content-center
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="10px"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                            </svg>
                          </span>
                          <span class="nav-text">Atributos de producto</span>
                        </router-link>
                      </li>

                      <li
                        class="nav-item"
                        v-if="
                          $parent.permissions.includes('product-variation-list')
                        "
                      >
                        <router-link
                          to="/admin/product-variation"
                          class="nav-link sub-nav-link"
                        >
                          <span
                            class="
                              svg-icon
                              nav-icon
                              d-flex
                              justify-content-center
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="10px"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                            </svg>
                          </span>
                          <span class="nav-text">Variaciones de producto</span>
                        </router-link>
                      </li>
                      <li
                        class="nav-item"
                        v-if="
                          $parent.permissions.includes('product-brand-list')
                        "
                      >
                        <router-link
                          to="/admin/product-brand"
                          class="nav-link sub-nav-link"
                        >
                          <span
                            class="
                              svg-icon
                              nav-icon
                              d-flex
                              justify-content-center
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="10px"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                            </svg>
                          </span>
                          <span class="nav-text">Marcas de producto</span>
                        </router-link>
                      </li>

                      <li
                        class="nav-item"
                        v-if="
                          $parent.permissions.includes('product-category-list')
                        "
                      >
                        <router-link
                          to="/admin/product-category"
                          class="nav-link sub-nav-link"
                        >
                          <span
                            class="
                              svg-icon
                              nav-icon
                              d-flex
                              justify-content-center
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="10px"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                            </svg>
                          </span>
                          <span class="nav-text">Categorías de producto</span>
                        </router-link>
                      </li>

                      <li
                        class="nav-item"
                        v-if="$parent.permissions.includes('product-list')"
                      >
                        <a
                          class="nav-link sub-nav-link"
                          data-toggle="collapse"
                          @click.prevent="setChildActive('product')"
                        >
                          <span
                            class="
                              svg-icon
                              nav-icon
                              d-flex
                              justify-content-center
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="10px"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                            </svg>
                          </span>
                          <span class="nav-text">Productos</span>
                          <i class="fas fa-chevron-right fa-rotate-90"></i>
                        </a>
                        <!-- <div class="collapse nav-collapse" id="catalogProduct" data-parent="#accordion1">
                                                
                                            </div> -->
                        <ul
                          class="nav flex-column"
                          :style="
                            activeChildItem == 'product' && toggleChildMenu
                              ? 'display:block'
                              : 'display:none'
                          "
                        >
                          <li
                            class="nav-item"
                            v-if="$parent.permissions.includes('product-list')"
                          >
                            <router-link
                              to="/admin/products"
                              class="nav-link mini-sub-nav-link"
                            >
                              <span class="nav-text">Lista de productos</span>
                            </router-link>
                          </li>

                          <li
                            class="nav-item"
                            v-if="
                              $parent.permissions.includes('product-manage')
                            "
                          >
                            <router-link
                              to="/admin/add-product"
                              class="nav-link mini-sub-nav-link"
                            >
                              <span class="nav-text">Añadir producto</span>
                            </router-link>
                          </li>
                          <!-- <li
                            class="nav-item"
                            v-if="
                              $parent.permissions.includes('import-product')
                            "
                          >
                            <router-link
                              to="/admin/import-export"
                              class="nav-link mini-sub-nav-link"
                            >
                              <span class="nav-text">Import / Export</span>
                            </router-link>
                          </li> -->
                          <li
                            class="nav-item"
                            v-if="
                              $parent.permissions.includes('import-product')
                            "
                          >
                            <router-link
                              to="/admin/verify-purchase-code"
                              class="nav-link mini-sub-nav-link"
                            >
                              <span class="nav-text">Importar / Exportar</span>
                            </router-link>
                          </li>
                        </ul>
                      </li>

                      <!-- <li class="nav-item">
                                            <a href="product-barcode.html" class="nav-link sub-nav-link">
                                                <span class="svg-icon nav-icon d-flex justify-content-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10px" height="10px" fill="currentColor" class="bi bi-circle" viewBox="0 0 16 16">
                                                        <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    </svg>
                                                </span>
                                                <span class="nav-text">Product Bar code Label</span>
                                            </a>
                                        </li> -->

                      <!-- <li
                        class="nav-item"
                        v-if="$parent.permissions.includes('product-reviews')"
                      >
                        <router-link
                          to="/admin/product-reviews"
                          class="nav-link sub-nav-link"
                        >
                          <span
                            class="
                              svg-icon
                              nav-icon
                              d-flex
                              justify-content-center
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="10px"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                            </svg>
                          </span>
                          <span class="nav-text">Product Reviews</span>
                        </router-link>
                      </li> -->
                    </ul>
                  </div>
                </div>
              </li>

              <!--begin::Nav Product Stock / Inventory -->

              <li class="nav-item" v-if="$parent.permissions.includes('stock')">
                <a
                  class="nav-link"
                  data-toggle="collapse"
                  href="javascript:void(0)"
                  @click.prevent="setActive('stock')"
                  :class="{ active: isActive('stock') }"
                >
                  <span class="svg-icon nav-icon">
                    <i class="fas fa-boxes font-size-h4"></i>
                  </span>
                  <span class="nav-text">Stock / Inventario</span>
                  <i class="fas fa-chevron-right fa-rotate-90"></i>
                </a>

                <div
                  :class="{ 'collapse nav-collapse': !isActive('stock') }"
                  class="nav-collapse"
                  id="stock"
                  data-parent="#accordion"
                >
                  <div id="accordion1">
                    <ul class="nav flex-column">
                      <li
                        class="nav-item"
                        v-if="$parent.permissions.includes('stock-list')"
                      >
                        <router-link
                          to="/admin/stocks"
                          class="nav-link sub-nav-link"
                        >
                          <span
                            class="
                              svg-icon
                              nav-icon
                              d-flex
                              justify-content-center
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="10px"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                            </svg>
                          </span>
                          <span class="nav-text">Inventario</span>
                        </router-link>
                      </li>
                      <li
                        class="nav-item"
                        v-if="$parent.permissions.includes('stock-manage')"
                      >
                        <router-link
                          to="/admin/add-stock"
                          class="nav-link sub-nav-link"
                        >
                          <span
                            class="
                              svg-icon
                              nav-icon
                              d-flex
                              justify-content-center
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="10px"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                            </svg>
                          </span>
                          <span class="nav-text">Añadir inventario</span>
                        </router-link>
                      </li>
                      <li
                        class="nav-item"
                        v-if="
                          $parent.permissions.includes('stock-transfer-list')
                        "
                      >
                        <router-link
                          to="/admin/stock-transfers"
                          class="nav-link sub-nav-link"
                        >
                          <span
                            class="
                              svg-icon
                              nav-icon
                              d-flex
                              justify-content-center
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="10px"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                            </svg>
                          </span>
                          <span class="nav-text">Lista de transferencias de stock</span>
                        </router-link>
                      </li>
                      <li
                        class="nav-item"
                        v-if="
                          $parent.permissions.includes('stock-transfer-manage')
                        "
                      >
                        <router-link
                          to="/admin/stock-transfer"
                          class="nav-link sub-nav-link"
                        >
                          <span
                            class="
                              svg-icon
                              nav-icon
                              d-flex
                              justify-content-center
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="10px"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                            </svg>
                          </span>
                          <span class="nav-text">Añadir transferencia de stock</span>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <!-- begin: Tickets management -->
              <li class="nav-item" v-if="$parent.permissions.includes('tickets')">
                <a
                  class="nav-link"
                  data-toggle="collapse"
                  href="javascript:void(0)"
                  @click.prevent="setActive('tickets-stock')"
                  :class="{ active: isActive('tickets-stock') }"
                >
                  <span class="svg-icon nav-icon">
                    <i class="fas fa-boxes font-size-h4"></i>
                  </span>
                  <span class="nav-text">Administración de boletos</span>
                  <i class="fas fa-chevron-right fa-rotate-90"></i>
                </a>
                <div
                  :class="{ 'collapse nav-collapse': !isActive('tickets-stock') }"
                  class="nav-collapse"
                  id="tickets-stock"
                  data-parent="#accordion"
                >
                  <div id="accordion1">
                    <ul class="nav flex-column">
                      <li
                        class="nav-item"
                      >
                        <router-link to="/admin/tickets-stock" class="nav-link sub-nav-link">
                          <span
                            class="
                              svg-icon
                              nav-icon
                              d-flex
                              justify-content-center
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="10px"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                            </svg>
                          </span>
                          <span class="nav-text">Días disponibles</span>
                        </router-link>
                      </li>
                      <li
                        class="nav-item"
                      >
                        <router-link to="/admin/tickets-data" class="nav-link sub-nav-link">
                          <span
                            class="
                              svg-icon
                              nav-icon
                              d-flex
                              justify-content-center
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="10px"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                            </svg>
                          </span>
                          <span class="nav-text">Boletos</span>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>

              <!--begin::Nav Sell / Orders -->

              <li
                class="nav-item"
                v-if="$parent.permissions.includes('sale-order')"
              >
                <a
                  class="nav-link"
                  data-toggle="collapse"
                  href="#order"
                  @click.prevent="setActive('sale')"
                  :class="{ active: isActive('sale') }"
                >
                  <span class="svg-icon nav-icon">
                    <i class="fas fa-clipboard-check font-size-h4"></i>
                  </span>
                  <span class="nav-text">Ventas / Ordenes</span>
                  <i class="fas fa-chevron-right fa-rotate-90"></i>
                </a>
                <div
                  :class="{ 'collapse nav-collapse': !isActive('sale') }"
                  class="nav-collapse"
                  id="order"
                  data-parent="#accordion"
                >
                  <ul class="nav flex-column">
                    <!-- <li
                      class="nav-item"
                      v-if="$parent.permissions.includes('sale-list')"
                    >
                      <router-link
                        to="/admin/sales"
                        class="nav-link sub-nav-link"
                      >
                        <span
                          class="
                            svg-icon
                            nav-icon
                            d-flex
                            justify-content-center
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10px"
                            height="10px"
                            fill="currentColor"
                            class="bi bi-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                            />
                          </svg>
                        </span>
                        <span class="nav-text">List</span>
                      </router-link>
                    </li> -->
                    <li
                      class="nav-item"
                      v-if="$parent.permissions.includes('order-list')"
                    >
                      <router-link
                        to="/admin/orders"
                        class="nav-link sub-nav-link"
                      >
                        <span
                          class="
                            svg-icon
                            nav-icon
                            d-flex
                            justify-content-center
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10px"
                            height="10px"
                            fill="currentColor"
                            class="bi bi-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                            />
                          </svg>
                        </span>
                        <span class="nav-text">Ordenes</span>
                      </router-link>
                    </li>
                    <li
                      class="nav-item"
                      v-if="$parent.permissions.includes('sale-manage')"
                    >
                      <router-link
                        to="/admin/add-sale"
                        class="nav-link sub-nav-link"
                      >
                        <span
                          class="
                            svg-icon
                            nav-icon
                            d-flex
                            justify-content-center
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10px"
                            height="10px"
                            fill="currentColor"
                            class="bi bi-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                            />
                          </svg>
                        </span>
                        <span class="nav-text">Añadir venta</span>
                      </router-link>
                    </li>
                    <li
                      class="nav-item"
                      v-if="$parent.permissions.includes('pos')"
                    >
                      <a href="/admin/pos" class="nav-link sub-nav-link">
                        <span
                          class="
                            svg-icon
                            nav-icon
                            d-flex
                            justify-content-center
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10px"
                            height="10px"
                            fill="currentColor"
                            class="bi bi-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                            />
                          </svg>
                        </span>
                        <span class="nav-text">POS</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item" v-if="$parent.permissions.includes('people')">
                <a class="nav-link" data-toggle="collapse" href="#People"
                    @click.prevent="setActive('people')" :class="{ active: isActive('people') }">
                    <span class="svg-icon nav-icon">
                        <i class="fas fa-user-friends font-size-h4"></i>
                    </span>
                    <span class="nav-text">People</span>
                    <i class="fas fa-chevron-right fa-rotate-90"></i>
                </a>
                <div :class="{ 'collapse nav-collapse': !isActive('people') }" class="nav-collapse"
                    id="People" data-parent="#accordion">
                    <div id="accordion2">
                        <ul class="nav flex-column">
                            <li class="nav-item" v-if="$parent.permissions.includes('role-list')">
                                <router-link to="/admin/roles" class="nav-link sub-nav-link">
                                    <span class="
                svg-icon
                nav-icon
                d-flex
                justify-content-center
              ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10px"
                                            height="10px" fill="currentColor" class="bi bi-circle"
                                            viewBox="0 0 16 16">
                                            <path fill-rule="evenodd"
                                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        </svg>
                                    </span>
                                    <span class="nav-text">Role</span>
                                </router-link>
                            </li>
                            <!-- <li class="nav-item">
                              <router-link to="/admin/biller" class="nav-link sub-nav-link">
                                  <span class="svg-icon nav-icon d-flex justify-content-center">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="10px" height="10px" fill="currentColor" class="bi bi-circle" viewBox="0 0 16 16">
                                              <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                          </svg>
                                  </span>
                                  <span class="nav-text">Billers</span>
                              </router-link>
                          </li> -->
                            <li class="nav-item" v-if="$parent.permissions.includes('customer-list')">
                                <router-link to="/admin/customer" class="nav-link sub-nav-link">
                                    <span class="
                svg-icon
                nav-icon
                d-flex
                justify-content-center
              ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10px"
                                            height="10px" fill="currentColor" class="bi bi-circle"
                                            viewBox="0 0 16 16">
                                            <path fill-rule="evenodd"
                                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        </svg>
                                    </span>
                                    <span class="nav-text">Customers</span>
                                </router-link>
                            </li>

                            <li class="nav-item" v-if="$parent.permissions.includes('supplier-list')">
                                <router-link to="/admin/suppliers" class="nav-link sub-nav-link">
                                    <span class="
                svg-icon
                nav-icon
                d-flex
                justify-content-center
              ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10px"
                                            height="10px" fill="currentColor" class="bi bi-circle"
                                            viewBox="0 0 16 16">
                                            <path fill-rule="evenodd"
                                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        </svg>
                                    </span>
                                    <span class="nav-text">Suppliers</span>
                                </router-link>
                            </li>

                            <li class="nav-item" v-if="$parent.permissions.includes('user-list')">
                                <router-link to="/admin/users" class="nav-link sub-nav-link">
                                    <span class="
                svg-icon
                nav-icon
                d-flex
                justify-content-center
              ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10px"
                                            height="10px" fill="currentColor" class="bi bi-circle"
                                            viewBox="0 0 16 16">
                                            <path fill-rule="evenodd"
                                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        </svg>
                                    </span>
                                    <span class="nav-text">Users</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
              <!--begin::Nav Business Settings -->

              <li
                class="nav-item"
                v-if="$parent.permissions.includes('business-setting')"
              >
                <a
                  class="nav-link"
                  data-toggle="collapse"
                  href="#setting"
                  @click.prevent="setActive('setting')"
                  :class="{ active: isActive('setting') }"
                >
                  <span class="svg-icon nav-icon">
                    <i class="fas fa-cogs font-size-h4"></i>
                  </span>
                  <span class="nav-text">Configuración del negocio</span>
                  <i class="fas fa-chevron-right fa-rotate-90"></i>
                </a>
                <div
                  :class="{ 'collapse nav-collapse': !isActive('setting') }"
                  class="nav-collapse"
                  id="setting"
                  data-parent="#accordion"
                >
                  <div id="accordion3">
                    <ul class="nav flex-column">
                      <li
                        class="nav-item"
                        v-if="$parent.permissions.includes('general-setting')"
                      >
                        <router-link
                          class="nav-link sub-nav-link"
                          data-toggle="collapse"
                          to="/admin/bussiness-setting"
                          role="button"
                          aria-expanded="false"
                          aria-controls="settingB"
                        >
                          <span
                            class="
                              svg-icon
                              nav-icon
                              d-flex
                              justify-content-center
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="10px"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                            </svg>
                          </span>
                          <span class="nav-text">Ajustes generales</span>
                        </router-link>
                      </li>

                      <li
                        class="nav-item"
                        v-if="$parent.permissions.includes('warehouse-list')"
                      >
                        <router-link
                          to="/admin/warehouse"
                          class="nav-link sub-nav-link"
                        >
                          <span
                            class="
                              svg-icon
                              nav-icon
                              d-flex
                              justify-content-center
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="10px"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                            </svg>
                          </span>
                          <span class="nav-text">Warehouse</span>
                        </router-link>
                      </li>
                      <!-- <li
                        class="nav-item"
                        v-if="$parent.permissions.includes('language-list')"
                      >
                        <router-link
                          to="/admin/languages"
                          class="nav-link sub-nav-link"
                        >
                          <span
                            class="
                              svg-icon
                              nav-icon
                              d-flex
                              justify-content-center
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="10px"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                            </svg>
                          </span>
                          <span class="nav-text">Language</span>
                        </router-link>
                      </li> -->
                      <li
                        class="nav-item"
                        v-if="$parent.permissions.includes('currency-list')"
                      >
                        <router-link
                          to="/admin/currencies"
                          class="nav-link sub-nav-link"
                        >
                          <span
                            class="
                              svg-icon
                              nav-icon
                              d-flex
                              justify-content-center
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="10px"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                            </svg>
                          </span>
                          <span class="nav-text">Moneda</span>
                        </router-link>
                      </li>
                      <li
                        class="nav-item"
                        v-if="
                          $parent.permissions.includes('payment-methods-list')
                        "
                      >
                        <router-link
                          to="/admin/payment-methods"
                          class="nav-link sub-nav-link"
                        >
                          <span
                            class="
                              svg-icon
                              nav-icon
                              d-flex
                              justify-content-center
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="10px"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                            </svg>
                          </span>
                          <span class="nav-text">Métodos de pago</span>
                        </router-link>
                      </li>

                      <!-- <li
                        class="nav-item"
                        v-if="
                          $parent.permissions.includes('shipping-methods-list')
                        "
                      >
                        <router-link
                          to="/admin/shipping-methods"
                          class="nav-link sub-nav-link"
                        >
                          <span
                            class="
                              svg-icon
                              nav-icon
                              d-flex
                              justify-content-center
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="10px"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                            </svg>
                          </span>
                          <span class="nav-text">Shipping Methods</span>
                        </router-link>
                      </li> -->
                      <!-- <li
                        class="nav-item"
                        v-if="
                          $parent.permissions.includes('shippment-with-city-list')
                        "
                      >
                        <router-link
                          to="/admin/shippment-with-city-setting"
                          class="nav-link sub-nav-link"
                        >
                          <span
                            class="
                              svg-icon
                              nav-icon
                              d-flex
                              justify-content-center
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="10px"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                            </svg>
                          </span>
                          <span class="nav-text">Shipment With City</span>
                        </router-link>
                      </li> -->
                      <!-- <li
                        class="nav-item"
                        v-if="$parent.permissions.includes('tax-setting-list')"
                      >
                        <router-link
                          to="/admin/tax-setting"
                          class="nav-link sub-nav-link"
                        >
                          <span
                            class="
                              svg-icon
                              nav-icon
                              d-flex
                              justify-content-center
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="10px"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                            </svg>
                          </span>
                          <span class="nav-text">Tax Settings</span>
                        </router-link>
                      </li> -->
                      <!-- <li
                        class="nav-item"
                        v-if="
                          $parent.permissions.includes('tax-setting-rate-list')
                        "
                      >
                        <router-link
                          to="/admin/tax-rate-setting"
                          class="nav-link sub-nav-link"
                        >
                          <span
                            class="
                              svg-icon
                              nav-icon
                              d-flex
                              justify-content-center
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="10px"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                            </svg>
                          </span>
                          <span class="nav-text">Tax Rate Settings</span>
                        </router-link>
                      </li> -->

                      <!-- <li
                        class="nav-item"
                        v-if="$parent.permissions.includes('coupon-list')"
                      >
                        <router-link
                          to="/admin/coupon-setting"
                          class="nav-link sub-nav-link"
                        >
                          <span
                            class="
                              svg-icon
                              nav-icon
                              d-flex
                              justify-content-center
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="10px"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                            </svg>
                          </span>
                          <span class="nav-text">Coupon Settings</span>
                        </router-link>
                      </li> -->
                      <li
                        class="nav-item"
                        v-if="$parent.permissions.includes('country-list')"
                      >
                        <router-link
                          to="/admin/country-setting"
                          class="nav-link sub-nav-link"
                        >
                          <span
                            class="
                              svg-icon
                              nav-icon
                              d-flex
                              justify-content-center
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="10px"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                            </svg>
                          </span>
                          <span class="nav-text">Paises</span>
                        </router-link>
                      </li>
                      <li
                        class="nav-item"
                        v-if="$parent.permissions.includes('state-list')"
                      >
                        <router-link
                          to="/admin/state-setting"
                          class="nav-link sub-nav-link"
                        >
                          <span
                            class="
                              svg-icon
                              nav-icon
                              d-flex
                              justify-content-center
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="10px"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                            </svg>
                          </span>
                          <span class="nav-text">Estados</span>
                        </router-link>
                      </li>
                      <li
                        class="nav-item"
                        v-if="$parent.permissions.includes('city-list')"
                      >
                        <router-link
                          to="/admin/city-setting"
                          class="nav-link sub-nav-link"
                        >
                          <span
                            class="
                              svg-icon
                              nav-icon
                              d-flex
                              justify-content-center
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="10px"
                              height="10px"
                              fill="currentColor"
                              class="bi bi-circle"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                              />
                            </svg>
                          </span>
                          <span class="nav-text">Ciudades</span>
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
            <br />
            <br />
            <br />
            <br />
          </div>
          <!--end::Menu Nav-->
        </div>
        <!--end::Menu Container-->
      </div>
      <!--end::Aside Menu-->
    </div>
  </div>
</template>
<script>
export default {
  name: "Sidebar",
  data() {
    return {
      activeItem: "",
      toggleMenu: false,
      activeChildItem: "",
      toggleChildMenu: "",
      setting: {},
    };
  },
  created: function () {
    this.setActive(this.$route.name);
  },
  methods: {
    isActive(menuItem) {
      return this.activeItem === menuItem && this.toggleMenu;
    },
    setActive(menuItem) {
      if (localStorage.getItem("menueItem") != menuItem) {
        this.toggleMenu = false;
      }
      localStorage.setItem("menueItem", menuItem);
      this.activeItem = menuItem;
      this.toggleMenu = !this.toggleMenu;
    },
    setChildActive(menuItem) {
      this.activeChildItem = menuItem;
      this.toggleChildMenu = !this.toggleChildMenu;
    },
    fetchSetting() {
      var token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      var responseData = {};
      axios
        .get("/api/admin/setting?type=is_purchased_setting", config)
        .then((res) => {
          for (var i = 0; i < res.data.data.length; i++) {
            Object.assign(responseData, {
              [res.data.data[i].setting_key]: res.data.data[i].setting_value,
            });
          }
          console.log(responseData, "setting ");
          this.setting = responseData;
        })
        .finally(() => console.log("working"));
    },
  },
  mounted() {
    console.log("permissions", this.$parent.permissions, "permissions");
    this.fetchSetting();
  },
  props: ["burgerMenu"],
};
</script>
